import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import * as JSPDF from 'jspdf';
import * as FileSaver from 'file-saver';
//import * as XLSX from 'xlsx';
import XLSX from 'xlsx-js-style';

import { FiltersService } from '@app/shared/services/filters.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { CustomDateFormatPipe } from '@app/shared/pipes/customdateformat.pipe';
import moment from 'moment-timezone';

declare function getHtmlElement(
  doc: HTMLElement,
  name: string,
  obj: any,
  printWithEvent: boolean,
  translations: any,
  printOrgNameAndBrand: boolean,
  date: any
): any;

declare function getHtmlElementReporHeader(
  doc: HTMLElement,
  name: string,
  obj: any,
  printWithEvent: boolean,
  translations: any,
  printOrgNameAndBrand: boolean,
  date: any
): any;

declare function getHtmlElementNonHeader(
  doc: HTMLElement,
  name: string,
  obj: any,
  printWithEvent: boolean,
  translations: any,
  printOrgNameAndBrand: boolean,
  date: any
): any;

@Injectable({ providedIn: 'root' })
export class HelperService {
  multiOrgSingleTitles: any[] = [];
  multiOrgExecuteCounter = 0;
  multiOrgCacheTitle: any = [];
  mOverLay = false;
  multiOrgCacheTitleToolTip: any = [];
  constructor(
    private filtersService: FiltersService,
    public translateService: TranslateService,
    public translationHelperService: TranslationHelperService,
    public gbfService: GlobalFilterService,
    private appConfig: AppConfigurationService,
    public customDateFormatPipe: CustomDateFormatPipe
  ) {}

  saveFile(name: string, type: string, data: any) {
    if (data != null && navigator.msSaveBlob) {
      return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    }
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none;');
    const blob = new Blob([data], { type: type });
    const fr = new FileReader();
    const that = this;
    fr.onload = function () {
      const r = JSON.parse(JSON.stringify(this.result));
      const blob2 = new Blob([data], { type: type });
      const url = window.URL.createObjectURL(blob2);
      a.setAttribute('href', url);
      a.setAttribute('download', name);
      document.getElementsByTagName('body')[0].append(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    };

    fr.readAsText(blob);
  }

  printPdf(fileName: string) {
    const data = document.documentElement;

    const widget = data.getElementsByClassName(
      'ng-trigger ng-trigger-overlayAnimation ng-tns-c9-3 action--menu ui-menu ui-widget ui-widget-content ui-corner-all ui-menu-dynamic ui-shadow ng-star-inserted'
    )[0];
    if (widget) {
      data
        .getElementsByClassName(
          'ng-trigger ng-trigger-overlayAnimation ng-tns-c9-3 action--menu ui-menu ui-widget ui-widget-content ui-corner-all ui-menu-dynamic ui-shadow ng-star-inserted'
        )[0]
        .setAttribute('data-html2canvas-ignore', 'true');
    }

    // html2canvas(data).then(canvas => {
    //     // Few necessary setting options
    //     const imgWidth = 208;
    //     const pageHeight = 295;
    //     const imgHeight = canvas.height * imgWidth / canvas.width;
    //     const heightLeft = imgHeight;

    //     const contentDataURL = canvas.toDataURL('image/png');

    //     const pdf = new JSPDF.default('p', 'mm', 'a4');
    //     const position = 0;
    //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //     pdf.save(fileName); // Generated PDF
    // });

    html2canvas(data).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const doc = new JSPDF.default('p', 'mm');
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      // Generated PDF
      doc.save('asdfghj' + '.pdf');
    });
  }

  public exportJsonAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // var range = XLSX.utils.decode_range(worksheet['!ref']);
    // let C, R = range.s.r;
    // for (C = range.s.c; C <= range.e.c; ++C) {

    //     const headerCell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })];
    //     if (headerCell && headerCell.t) {
    //         worksheet[XLSX.utils.encode_cell({ c: C, r: R })].s = { font: { bold: true } };
    //     }
    // }
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  generateOrgTitle() {
    let title: any = [];
    if (this.gbfService.multi_org.length > 0) {
      const maxOrgLevel = Math.max.apply(
        Math,
        this.gbfService['multi_org'].map((o: any) => {
          return o.level;
        })
      );
      const levelSelection = this.gbfService['multi_org'].filter((x: any) => x.level === maxOrgLevel);
      if (levelSelection.length > 1) {
        const orgSelectedList: any = [];
        levelSelection.forEach((ls: any) => {
          let tempOrg: any = ls;
          const multiOrgSingleTitles: any = [];
          while (tempOrg && (tempOrg.parent !== 'null' || tempOrg.parent !== null)) {
            multiOrgSingleTitles.push({ label: tempOrg.label });
            tempOrg = this.gbfService['multi_org'].find((x: any) => x.id === tempOrg.parent);
          }
          multiOrgSingleTitles.reverse();
          orgSelectedList.push(multiOrgSingleTitles);
        });
        title = [{ label: `${levelSelection[0].label} and ${levelSelection.length - 1} More Selected` }];
      } else if (levelSelection.length === 1) {
        const multiOrgSingleTitles: any = [];
        title = `${levelSelection[0].label}`;
        let tempOrg: any = levelSelection[0];
        while (tempOrg && (tempOrg.parent !== 'null' || tempOrg.parent !== null)) {
          multiOrgSingleTitles.push({ label: tempOrg.label });
          tempOrg = this.gbfService['multi_org'].find((x: any) => x.id === tempOrg.parent);
        }
        title = multiOrgSingleTitles.reverse();
      } else {
        title = [{ label: `Selected` }];
      }
    }
    return title;
  }

  public exportTableAsExcelFile(
    htmlElement: HTMLElement,
    excelFileName: string,
    printWithEvent: boolean = true,
    isRawString: boolean = false,
    printOrgNameAndBrand: boolean = true,
    startDate: string = null,
    endDate: string = null,
    isReporting: boolean = true,
    isMuliHead: boolean = false
  ): void {
    try {
      var obj = this.filtersService.getFilterObject();
      var activeHierarchy = this.gbfService.getActiveOrgHierarchy();
      var found = this.gbfService.current_configs.find((x: any) => x.localStorageKey == 'gbf_multi_org');
      if (found) {
        let title: any = [];
        if (this.gbfService?.multi_org?.length > 0) {
          const maxOrgLevel = Math.max.apply(
            Math,
            this.gbfService['multi_org'].map((o: any) => {
              return o.level;
            })
          );
          const levelSelection = this.gbfService['multi_org'].filter((x: any) => x.level === maxOrgLevel);
          if (levelSelection.length > 1) {
            const orgSelectedList: any = [];
            levelSelection.forEach((ls: any) => {
              let tempOrg: any = ls;
              const multiOrgSingleTitles: any = [];
              while (tempOrg && (tempOrg.parent !== 'null' || tempOrg.parent !== null)) {
                multiOrgSingleTitles.push({ label: tempOrg.label });
                tempOrg = this.gbfService['multi_org'].find((x: any) => x.id === tempOrg.parent);
              }
              multiOrgSingleTitles.reverse();
              orgSelectedList.push(multiOrgSingleTitles);
            });
            this.multiOrgCacheTitleToolTip = orgSelectedList;
            title = [{ label: `${levelSelection[0].label} and ${levelSelection.length - 1} More Selected` }];
          } else if (levelSelection.length === 1) {
            const multiOrgSingleTitles: any = [];
            title = `${levelSelection[0].label}`;
            let tempOrg: any = levelSelection[0];
            while (tempOrg && (tempOrg.parent !== 'null' || tempOrg.parent !== null)) {
              multiOrgSingleTitles.push({ label: tempOrg.label });
              tempOrg = this.gbfService['multi_org'].find((x: any) => x.id === tempOrg.parent);
            }
            title = multiOrgSingleTitles.reverse();
            this.multiOrgCacheTitleToolTip = [];
          } else {
            title = [{ label: `Selected` }];
            this.multiOrgCacheTitleToolTip = [];
          }
        }
        this.multiOrgExecuteCounter += 1;
        if (title.length == 1) {
          obj.OrgName = title[0].label;
        } else {
          var name = '';
          var i = 0;
          while (i <= title.length - 1) {
            name += this.translateService.instant(title[i].label);
            if (i != title.length - 1) {
              name = name + ' ' + '>' + ' ';
            }
            i = i + 1;
          }
          obj.OrgName = name;
        }
      } else {
        var hierarchy: any = JSON.parse(localStorage.getItem('gbf_org'));
        if (hierarchy != null && Array.isArray(hierarchy.selectedTreeItems)) {
          var orgName = '';
          var count = hierarchy.selectedTreeItems.length;
          hierarchy.selectedTreeItems.forEach((element: any, index: number, arr: any[]) => {
            if ((element.label != null || element.label !== undefined) && element.level <= activeHierarchy.OrgLevel) {
              if (index === 0) {
                orgName = element.label;
              } else {
                orgName += ' > ' + element.label;
              }
            }
          });
          if (orgName !== '') {
            count = null;
            hierarchy = null;
            obj.OrgName = this.translateService.instant(orgName);
          }
        } else {
          obj.OrgName = this.translateService.instant(activeHierarchy.OrgName);
        }
      }

      // var genOrg = this.generateOrgTitle();

      // if (genOrg.length == 1) {
      //   obj.OrgName = genOrg[0].label;
      // } else {
      //   obj.OrgName = genOrg.map((a: { label: any }) => a.label).join(' > ');
      // }

      if (startDate && endDate) {
        obj.StartDate = startDate;
        obj.EndDate = endDate;
      }

      obj.Event = this.translationHelperService.GetEventTranslation(obj.Event);
      var newobj: any = {};
      Object.keys(obj).forEach((key: any) => {
        let nKey = '';
        if (isReporting) {
          nKey = key == 'EndDate' || key == 'StartDate' ? moment(obj[key]).format('MMM D, YYYY') : obj[key];
        } else {
          nKey = obj[key];
        }
        newobj[key] = {
          translation: this.translateService.instant(key),
          value: nKey,
        };
      });
      var translations = {
        reportname: this.translateService.instant('Report Name'),
        daterange: this.translateService.instant('Date Range'),
        brand: this.translateService.instant('Brand'),
        eventtype: this.translateService.instant('Event Type'),
        reportdate: this.translateService.instant('Report Date'),
      };

      var d = new Date();
      //let dateStr = d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
      let dateStr = d.toString();

      let date;
      if (isReporting) {
        date = this.customDateFormatPipe.transform(dateStr, 'date');
      } else {
        date = this.customDateFormatPipe.transform(dateStr, 'admindate');
      }

      // Formate Code Chagnes
      const html = getHtmlElementNonHeader(
        htmlElement,
        this.translateService.instant(excelFileName),
        newobj,
        printWithEvent,
        translations,
        printOrgNameAndBrand,
        date
      );
      const html2 = getHtmlElementReporHeader(
        htmlElement,
        this.translateService.instant(excelFileName),
        newobj,
        printWithEvent,
        translations,
        printOrgNameAndBrand,
        date
      );

      let worksheet: XLSX.WorkSheet = null;
      let worksheetHeader: XLSX.WorkSheet = null;

      if (isRawString === true) {
        worksheet = XLSX.utils.table_to_sheet(html, { raw: isRawString });
      } else {
        worksheet = XLSX.utils.table_to_sheet(html, { dateNF: this.customDateFormatPipe.getFormat() });
      }

      if (isRawString === true) {
        worksheetHeader = XLSX.utils.table_to_sheet(html2, { raw: isRawString });
      } else {
        worksheetHeader = XLSX.utils.table_to_sheet(html2, { dateNF: this.customDateFormatPipe.getFormat() });
      }

      const workbook: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheetHeader, this.translateService.instant('Filter'));
      XLSX.utils.book_append_sheet(workbook, worksheet, this.translateService.instant('Result'));

      if (!isMuliHead) {
        this.formatResult(workbook.Sheets['Result']);
      } else {
        this.formatResultMulti(workbook.Sheets['Result']);
      }
      this.formatFilter(workbook.Sheets['Filter']);

      this.fixWidth(worksheetHeader);
      this.fixWidth(worksheet);

      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    } catch (e) {
      console.log(e);
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private formatResult(obj: any) {
    var columns = obj;
    for (const key in columns) {
      if (
        columns.hasOwnProperty(key) &&
        key.indexOf('ref') == -1 &&
        key.indexOf('row') == -1 &&
        key.indexOf('col') == -1
      ) {
        if (key.length == 2) {
          if (key.indexOf('1') != -1) {
            obj[key].s = {
              font: {
                // name: "arial",
                color: { rgb: '00000000' },
                bold: true,
              },
              alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: '1',
              },
              border: {
                top: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                bottom: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                left: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                right: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
              },

              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'FFF2F2F2' },
              },
            };
          } else {
            obj[key].s = this.getDataStyle();
          }
        } else {
          obj[key].s = this.getDataStyle();
        }
      }
    }
  }

  private formatFilter(obj: any) {
    var columns = obj;
    for (const key in columns) {
      if (
        columns.hasOwnProperty(key) &&
        key.indexOf('ref') == -1 &&
        key.indexOf('row') == -1 &&
        key.indexOf('col') == -1
      ) {
        if (key.length == 2) {
          if (key.indexOf('1') != -1) {
            obj[key].s = {
              font: {
                // name: "arial",
                color: { rgb: '00000000' },
                bold: true,
              },
              alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: '1',
              },
              border: {
                top: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                bottom: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                left: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                right: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
              },

              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'FFF2F2F2' },
              },
            };
          } else {
            obj[key].s = this.getDataStyle();
          }
        } else {
          obj[key].s = this.getDataStyle();
        }
      }
    }
  }

  private formatResultMulti(obj: any) {
    var columns = obj;
    for (const key in columns) {
      if (
        columns.hasOwnProperty(key) &&
        key.indexOf('ref') == -1 &&
        key.indexOf('row') == -1 &&
        key.indexOf('col') == -1
      ) {
        if (key.length == 2) {
          if (key.indexOf('1') != -1 || key.indexOf('2') != -1) {
            obj[key].s = {
              font: {
                // name: "arial",
                color: { rgb: '00000000' },
                bold: true,
              },
              alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: '1',
              },
              border: {
                top: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                bottom: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                left: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
                right: {
                  style: 'thin',
                  color: { rgb: 'FF000000' },
                },
              },

              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'FFF2F2F2' },
              },
            };
          } else {
            obj[key].s = this.getDataStyle();
          }
        } else {
          obj[key].s = this.getDataStyle();
        }
      }
    }
  }

  private fixWidth(worksheet: XLSX.WorkSheet) {
    const data = XLSX.utils.sheet_to_json<any>(worksheet);
    if (data.length > 0) {
      const colLengths = Object.keys(data[0]).map((k) => k.toString().length);
      for (const d of data) {
        Object.values(d).forEach((element: any, index) => {
          const length = element.toString().length;
          if (colLengths[index] < length) {
            colLengths[index] = length;
          }
        });
      }
      worksheet['!cols'] = colLengths.map((l) => {
        return {
          wch: l,
        };
      });
    }
  }

  // tslint:disable-next-line: member-ordering
  exportExelFile(data: any, filename: string) {
    const blobValue = new Blob([data['body']], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blobValue, filename + '.xlsx');
  }
  // tslint:disable-next-line: member-ordering
  exportCSVFile(data: any, filename: string) {
    const blobValue = new Blob([data['body']], {
      type: 'text/csv',
    });
    FileSaver.saveAs(blobValue, filename + '.csv');
  }

  getDataStyle() {
    return {
      font: {
        // name: "arial",
        color: { rgb: 'FF000000' },
        // sz: "30",
      },
      alignment: {
        // vertical: "center",
        // horizontal: "center",
        wrapText: '1',
      },
      border: {
        top: {
          style: 'thin',
          color: { rgb: 'FF000000' },
        },
        bottom: {
          style: 'thin',
          color: { rgb: 'FF000000' },
        },
        left: {
          style: 'thin',
          color: { rgb: 'FF000000' },
        },
        right: {
          style: 'thin',
          color: { rgb: 'FF000000' },
        },
      },
      width: 30,
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFFFFF' },
      },
    };
  }

  downloadResource(data: any, filename: string) {
    const blobValue = new Blob([data['body']], {
      type: this.getMimeType(filename),
    });
    FileSaver.saveAs(blobValue, filename);
  }

  // tslint:disable-next-line: member-ordering
  addPropertiesToObject(srcObj: any, destObj: any) {
    if (destObj && srcObj) {
      // Add properties
      // tslint:disable-next-line: forin
      for (const key in srcObj) {
        destObj[key] = srcObj[key];
      }
    }
  }

  private getMimeType(fileName: string) {
    const type = new Map<string, string>();
    type.set('pdf', 'application/pdf');
    type.set('doc', 'application/vnd.ms-word');
    type.set('docx', 'application/vnd.ms-word');
    type.set('xls', 'application/vnd.ms-excel');
    type.set('xlsx', 'application/vnd.openxmlformats officedocument.spreadsheetml.sheet');
    type.set('png', 'image/png');
    type.set('jpg', 'image/jpeg');
    type.set('csv', 'text/csv');
    type.set('bmp', 'image/bmp');

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(fileName)[1];
    return type.get(ext);
  }

  public fileValidation(type: string) {
    let fileType: any = this.appConfig.getByIdentifier(Identifiers.FileValidation);
    if (fileType) {
      let module = fileType['Fields'].find((res: any) => {
        return res.module == type;
      });
      if (!module) {
        module = fileType['Fields'].find((res: any) => {
          return res.module == 'all';
        });
      }
      return module;
    }
  }

  getDateFormat(option: string = 'date') {
    const FieldNames: any = this.appConfig.getByIdentifier('custom-date-formats');
    switch (option) {
      case 'date':
        return FieldNames.Fields.date;
      case 'date_primeng':
        return FieldNames.Fields.date_primeng;
      case 'time':
        FieldNames.Fields.time;
      case 'datetime':
        return FieldNames.Fields.datetime;
    }
    return FieldNames.Fields.date;
  }
}
